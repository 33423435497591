import { ADMIN_LOGIN_TOKEN } from "../../constants";
import { getItemFromLocalStorage } from "../../util";
import { APIMethods, K_BOMBAY_APPNAME } from "./constants";
import { ApiResponse } from "./interfaces";
// import * as https from 'https';

// const agent = new https.Agent({
//   rejectUnauthorized: false
// });
process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

class ApiService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  private async request<T>(
    endpoint: string,
    method: APIMethods.GET | APIMethods.POST | APIMethods.PUT | APIMethods.DELETE = APIMethods.GET,
    data: any = null,
    headers: Record<string, string> = {}
  ): Promise<ApiResponse<T>> {

    const loginData = getItemFromLocalStorage(ADMIN_LOGIN_TOKEN);
    const parsedData = loginData ? JSON.parse(loginData) : {};
    const accessTokenVal = parsedData.accessToken  ?? "";
    const userNameVal = parsedData.username ?? "";
    // app.use((req, res, next) => {
    //   res.header('Access-Control-Allow-Origin', '*');
    //   res.header('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    //   next();
    // });
    // const httpsAgent = new Agent({
    //   rejectUnauthorized: false,
    // });
    
    const config: RequestInit = {
      method,
      // agent: httpsAgent as any,
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        appName: K_BOMBAY_APPNAME,
        Authorization: `Bearer ${accessTokenVal}`,
        userName: userNameVal,
        ...headers,
      },
    };

    if (data) {
      config.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(`${this.baseUrl}${endpoint}`, config);
      // console.log('API call response:', response);
      if (!response.ok) {
        if (response.status === 401) {
          alert('Session expired. Logout and login again.') // imporve ux here
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseData: T = await response.json();
      console.log(`${endpoint} call response.json():`, responseData);
      return { data: responseData };
    } catch (error) {
      console.error(`${endpoint} call error:`, error);
      throw error;
    }
  }

  get<T>(endpoint: string, headers: Record<string, string> = {}): Promise<ApiResponse<T>> {
    return this.request<T>(endpoint, APIMethods.GET, null, headers);
  }

  post<T>(endpoint: string, data: any, headers: Record<string, string> = {}): Promise<ApiResponse<T>> {
    return this.request<T>(endpoint, APIMethods.POST, data, headers);
  }

  put<T>(endpoint: string, data: any, headers: Record<string, string> = {}): Promise<ApiResponse<T>> {
    return this.request<T>(endpoint, APIMethods.PUT, data, headers);
  }

  delete<T>(endpoint: string, headers: Record<string, string> = {}): Promise<ApiResponse<T>> {
    return this.request<T>(endpoint, APIMethods.DELETE, null, headers);
  }
}

export default ApiService;
