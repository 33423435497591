import React, { useCallback, useState } from "react";
import { updateCollection } from "../../services/firebase";
import {
  FIREBASE_COLLECTION_DOC_KEY,
  FIREBASE_COLLECTION_KEY,
  ROUTE_PATHS,
} from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFatafatTableAddEditInitialState,
  getCurrentDayGames,
  getLastDigitOfANumber,
  getPastDaysGames,
  sumOfDigits,
  convertTime12to24,
} from "../../util";
import "./css/style.min.css";
import "./css/matkaplay.css";
import "../common.css";
import { API_CONFIGS } from "../../services/fetchAPI/constants";
import { APIService } from "../../App";

const AddFatafatResultScreen = ({ data, fatafatTableList }) => {
  const navigate = useNavigate();

  const params = useParams();

  const selectedGameIndex = params?.fatafatGuessId ?? null;
  const [{ startTime, guessNumber }, setState] = useState(
    getFatafatTableAddEditInitialState(
      getCurrentDayGames(fatafatTableList ?? []),
      selectedGameIndex
    )
  );
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const createFatafatTableAPICall = (body) => {
    APIService.post(API_CONFIGS.CREATE_FATAFAT.URL, body)
      .then((response) => {
        console.log("createFatafatTableAPICall API_CONFIGS.CREATE_FATAFAT.URL", response);
        if (response?.data?.model) {
          alert(`Success: ${response?.data?.sucessMsg}`);
          navigate(ROUTE_PATHS.HOME);
        } else if (response?.data?.errMessages && response?.data?.errMessages.length > 0) {
          const errorMSg = response.data.errMessages[0].errMessage;
          alert(errorMSg);
        }
      })
      .catch((error) => {
        console.error("Error createFatafatTableAPICall:", error);
        alert(error);
      });
  }
  const updatFatafatTableAPICall = (body) => {
    console.log("update body", body);
    APIService.post(API_CONFIGS.UPDATE_FATAFAT.URL, body)
      .then((response) => {
        // setData([...data, response.data]);
        console.log("updatFatafatTableAPICall API_CONFIGS.UPDATE_GUESS_TABLE.URL", response);
        if (response?.data?.status) {
          alert(`Success: ${response?.data?.sucessMsg}`);
          navigate(ROUTE_PATHS.HOME);
        } else if (response?.data?.errMessages && response?.data?.errMessages.length > 0) {
          const errorMSg = response.data.errMessages[0].errMessage;
          alert(errorMSg);
        }
      })
      .catch((error) => {
        console.error("Error updatFatafatTableAPICall:", error);
        alert(error);
      });
  }

  const handleAddGameSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (startTime !== "" && guessNumber !== "") {
        const gameObject = {
          startTime: convertTime12to24(startTime),
          guessNumber: guessNumber,
          // winnerValue: getLastDigitOfANumber(
          //   sumOfDigits(parseInt(guessNumber))
          // ).toString(),
          // createdTime: new Date().toISOString(),
        };
        // const pastDaysGameList = getPastDaysGames(data?.fatafatResults ?? []);
        // const currentDayGameList = getCurrentDayGames(
        //   data?.fatafatResults ?? []
        // );
        if (selectedGameIndex === null) {
          // currentDayGameList.push(gameObject);
          createFatafatTableAPICall(gameObject);
        } else {
          // currentDayGameList[selectedGameIndex] = gameObject;
          updatFatafatTableAPICall({...gameObject, fatafatGuessId: selectedGameIndex})
        }

        // let updatedData = {
        //   ...data,
        //   fatafatResults: [
        //     ...new Set([...pastDaysGameList, ...currentDayGameList]),
        //   ],
        // };
        // console.log("handleAddGameSubmit data", updatedData);
        // updateCollection(
        //   FIREBASE_COLLECTION_KEY,
        //   FIREBASE_COLLECTION_DOC_KEY,
        //   updatedData
        // )
        //   .then((response) => {
        //     console.info("firebase updated successfully!", response);
        //     setTimeout(() => {
        //       navigate(ROUTE_PATHS.HOME);
        //     }, 100);
        //   })
        //   .catch((e) => {
        //     console.error("firebase updated failed!", e);
        //   });
      } else {
        alert("Enter all the details.");
      }
    },
    [data, navigate, guessNumber, selectedGameIndex, startTime]
  );

  return (
    <div className="login">
      <div className="container round_border_card">
        <h2 className="text-center">
          {selectedGameIndex === null
            ? "Add New Fatafat Result"
            : "Update Fatafat Result Detail"}
        </h2>
        <br />
        <form
          name="adminAddFatafatResultGame"
          validate
          onSubmit={handleAddGameSubmit}
          className="loginForm"
        >
          <div className="input-group mt-2">
            <label className="label font-weight-bold">Select Time</label>
            {data?.fatafat_result_timings &&
              data.fatafat_result_timings.map((timing, index) => {
                return (
                  <tbody key={`${timing}_${index}`}>
                    <tr>
                      <td>
                        <input
                          type="radio"
                          id="startTime"
                          name="startTime"
                          // className="mb-0"
                          required
                          value={timing}
                          checked={startTime === timing}
                          onChange={handleChange}
                        />
                        <span
                          className="font-weight-bold ml-7"
                          style={{ marginLeft: "5px" }}
                        >
                          {timing}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            {/* <input
                type="time"
                id="startTime"
                name="startTime"
                className="form-control"
                placeholder="Enter the game time*"
                required
                onChange={handleChange}
                value={startTime}
              /> */}
            <span className="error-message"></span>
          </div>

          <div className="input-group mt-2">
            <label className="label">Game Result</label>
            <input
              type="number"
              id="guessNumber"
              name="guessNumber"
              className="form-control"
              placeholder="Enter the game results*"
              maxLength={3}
              minLength={3}
              onChange={handleChange}
              value={guessNumber}
            />
            <span className="error-message"></span>
          </div>
          <br />
          <button className="button btn btn-custom btn-lg" type="submit">
            {selectedGameIndex === null ? "Add" : "Update"}
          </button>
          <button
            className="button btn btn-custom btn-lg mt-2 ml-0"
            onClick={() => navigate(ROUTE_PATHS.HOME)}
            style={{ marginTop: "10px" }}
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddFatafatResultScreen;
