// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import firebase from "firebase/app";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// import { isDev } from "./util";
// import { getAnalytics } from "firebase/analytics";

// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// "site": "tatakamatkaplaydev",
// firebase deploy --only hosting:tatakamatkaplaydev
export const firebaseConfigDevApp = {
  basename: "",
  defaultPath: "",
  theme: "light",
  presetColor: "default", // default, theme1, theme2, theme3, theme4, theme5, theme6
  // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  i18n: "en",
  rtlLayout: false,
  firebase: {
    apiKey: "AIzaSyAENz8g2ZTyA1arOmjr48MjU_qXaHBwXyI",
    authDomain: "shantimatka-9292f.firebaseapp.com",
    projectId: "shantimatka-9292f",
    storageBucket: "shantimatka-9292f.appspot.com",
    messagingSenderId: "1048364789832",
    appId: "1:1048364789832:web:6b8ef94b6fdd6fa61a687d",
    measurementId: "G-RRBMRXHY1X",
    // databaseURL: "https://katata-matkaplay-default-rtdb.firebaseio.com",
  },
};
export const appConfigProd = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: "",
  defaultPath: "",
  theme: "light",
  presetColor: "default", // default, theme1, theme2, theme3, theme4, theme5, theme6
  // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  i18n: "en",
  rtlLayout: false,
  firebase: {
    apiKey: "AIzaSyAENz8g2ZTyA1arOmjr48MjU_qXaHBwXyI",
    authDomain: "shantimatka-9292f.firebaseapp.com",
    projectId: "shantimatka-9292f",
    storageBucket: "shantimatka-9292f.appspot.com",
    messagingSenderId: "1048364789832",
    appId: "1:1048364789832:web:6b8ef94b6fdd6fa61a687d",
    measurementId: "G-RRBMRXHY1X",
  },
};

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfigDevApp.firebase);
// export const analytics = getAnalytics(firebaseApp);
