import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import {
  getCurrentDayGames,
  onTimeChange,
  sortListByHHMMTime,
} from "../../../util";

const GamesTimeTableCard = ({ data }) => {
  // const todaysGames = sortListByHHMMTime(data.gamesTimeTable);//getCurrentDayGames(data);
  return (
    <div className="container-fluid" style={{ paddingTop: "5%" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 main-time">
            <div className="win-left">
              <h2
                style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                Matka Game Time Table
                <span>
                  <FontAwesomeIcon icon={faClock} size="sm" />
                </span>
              </h2>
              <div className="time-table">
                {data.length === 0 ? (
                  <div className="shadow_card">
                    <h1>No games created yet!</h1>
                  </div>
                ) : (
                  <table>
                    <tbody>
                      <tr>
                        <th>Market</th>
                        <th>open</th>
                        <th>close</th>
                      </tr>
                      {data.map((item, index) => {
                        return (
                          <tr key={`tibletable-${index}`}>
                            <td>{item.name}</td>
                            <td>{onTimeChange(item.startTime)}</td>
                            <td>{onTimeChange(item.endTime)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamesTimeTableCard;
