import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../constants";
import { getCurrentDayGames } from "../../../util";
import editIcon from "../images/edit.png";
import deleteIcon from "../images/delete_red.png";
import { APIService } from "../../../App";
import { API_CONFIGS } from "../../../services/fetchAPI/constants";

const FatafatResultsCard = ({ isAdminLoggedIn, data = [] }) => {
  const navigate = useNavigate();
  const todaysGames = getCurrentDayGames(data);

  const onEditGame = useCallback((fatafatGuessId) => {
    navigate(`${ROUTE_PATHS.ADD_FATAFAT_RESULT}/${fatafatGuessId}`);
  }, [navigate]);

  const onDeleteFatafatTableAPICall = (body) => {
    APIService.post(API_CONFIGS.DELETE_FATAFAT.URL, body)
      .then((response) => {
        // setData([...data, response.data]);
        console.log(
          "onDeleteFatafatTableAPICall API_CONFIGS.DELETE_FATAFAT.URL",
          response
        );
        if (response?.data?.status) {
          alert(`Success: ${response?.data?.sucessMsg}`);
          window.location.reload();
        } else if (
          response?.data?.errMessages &&
          response?.data?.errMessages.length > 0
        ) {
          const errorMSg = response.data.errMessages[0].errMessage;
          alert(errorMSg);
        }
      })
      .catch((error) => {
        console.error("Error onDeleteFatafatTableAPICall:", error);
      });
  };

  const onDelete = (body) => {
    if (window.confirm("Are you sure you want to delete this game?")) {
      // Save it!
      onDeleteFatafatTableAPICall(body);
    } else {
      // Do nothing!
      console.log("Thing was not deleted to the database.");
    }
  };

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="matka-results" style={{ paddingBottom: 0 }}>
          <h2>Shanti Fatafat</h2>
          <div className="test">
            {isAdminLoggedIn && (
              <div className="d-flex align-items-center test">
                {/* <img
                  className="img-btn"
                  src={editIcon}
                  title="Edit game"
                  alt="edit-game"
                  onClick={onEditGame}
                /> */}
                <Link
                  to={ROUTE_PATHS.ADD_FATAFAT_RESULT}
                  title="Add Fatafat Result"
                  className="add-fatafat-btn"
                >
                  <b>+</b>
                </Link>
              </div>
            )}
            <Link
              to={ROUTE_PATHS.FATAFAT_RESULT_DETAIL}
              title="Fatafat chart"
              className="shantiMatkaChartBtn"
            >
              Chart
            </Link>
          </div>
        </div>
        {todaysGames.length === 0 ? (
          <div className="shadow_card">
            <h1>No results available!</h1>
          </div>
        ) : (
          <div className="fatafat-card">
            <table>
              <tbody style={{ alignSelf: "baseline" }}>
                <tr>
                  <th>Round</th>
                  <th>Result</th>
                  {isAdminLoggedIn && <th></th>}
                </tr>
                {todaysGames.map((item, index) => (
                  <tr key={`fatafattable-${item?.fatafatGuessId}-${index}`}>
                    <td>{item.startTime}</td>
                    <td>
                      {item.guessNumber} - {item.winnerValue}
                    </td>
                    {isAdminLoggedIn && (
                      <td>
                        <div style={{ marginLeft: 10 }}>
                          <img
                            className="img-btn"
                            style={{ height: 25, width: 25 }}
                            src={editIcon}
                            title="Edit game"
                            alt="edit-game"
                            onClick={() => onEditGame(item.fatafatGuessId)}
                          />
                          <img
                            className="img-btn"
                            style={{ height: 25, width: 25 }}
                            src={deleteIcon}
                            title={`delete game`}
                            alt={`delete-game`}
                            onClick={() =>
                              onDelete({ fatafatGuessId: item.fatafatGuessId })
                            }
                          />
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
                {/* {todaysGames
                  .filter((_item, index) => index % 2 === 0)
                  .map((item, index) => (
                    <tr key={`fatafattable-${item?.fatafatGuessId}-${index}`}>
                      <td>{item.startTime}</td>
                      <td>
                        {item.guessNumber} - {item.winnerValue}
                      </td>
                    </tr>
                  ))} */}
              </tbody>
              {/* <tbody style={{alignSelf: 'baseline'}}>
                <tr>
                  <th>Round</th>
                  <th>Result</th>
                </tr>
                {todaysGames
                  .filter((_item, index) => index % 2 !== 0)
                  .map((item, index) => (
                    <tr key={`fatafattable-${item?.fatafatGuessId}-${index}`}>
                      <td>{item.startTime}</td>
                      <td>
                        {item.guessNumber} - {item.winnerValue}
                      </td>
                    </tr>
                  ))}
              </tbody> */}
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default FatafatResultsCard;
