import React, { useCallback, useState } from "react";
import { updateCollection } from "../../services/firebase";
import {
  FIREBASE_COLLECTION_DOC_KEY,
  FIREBASE_COLLECTION_KEY,
  ROUTE_PATHS,
} from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  getGuessTableAddEditInitialState,
  getCurrentDayGames,
  getLastDigitOfANumber,
  getPastDaysGames,
  sumOfDigits,
  isEmpty,
} from "../../util";
import "./css/style.min.css";
import "./css/matkaplay.css";
import "../common.css";
import { API_CONFIGS } from "../../services/fetchAPI/constants";
import { APIService } from "../../App";

const AddEditGuessingTableScreen = ({ data, guessingTableList }) => {
  const navigate = useNavigate();

  const params = useParams();

  const selectedGameIndex = params?.guessGameId ?? null;
  const [{ guessNumber, gameName, guessGameId, createdTime }, setState] = useState(
    getGuessTableAddEditInitialState(
      getCurrentDayGames(guessingTableList ?? []),
      selectedGameIndex
    )
  );

  const createGuessTableAPICall = (body) => {
    APIService.post(API_CONFIGS.CREATE_GUESS_TABLE.URL, body)
      .then((response) => {
        console.log("createGuessTableAPICall API_CONFIGS.CREATE_GUESS_TABLE.URL", response);
        if (response?.data?.model) {
          alert(`Success: ${response?.data?.sucessMsg}`);
          navigate(ROUTE_PATHS.HOME);
        } else if (response?.data?.errMessages && response?.data?.errMessages.length > 0) {
          const errorMSg = response.data.errMessages[0].errMessage;
          alert(errorMSg);
        }
      })
      .catch((error) => {
        console.error("Error createGuessTableAPICall:", error);
      });
  }
  const updateGuessTableAPICall = (body) => {
    APIService.post(API_CONFIGS.UPDATE_GUESS_TABLE.URL, body)
      .then((response) => {
        // setData([...data, response.data]);
        console.log("updateGuessTableAPICall API_CONFIGS.UPDATE_GUESS_TABLE.URL", response);
        if (response?.data?.status) {
          alert(`Success: ${response?.data?.sucessMsg}`);
          navigate(ROUTE_PATHS.HOME);
        } else if (response?.data?.errMessages && response?.data?.errMessages.length > 0) {
          const errorMSg = response.data.errMessages[0].errMessage;
          alert(errorMSg);
        }
      })
      .catch((error) => {
        console.error("Error updateGuessTableAPICall:", error);
      });
  }

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const handleAddGameSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!isEmpty(guessNumber)) {
        const gameObject = {
          gameName,
          guessNumber: guessNumber,
          winnerValue: getLastDigitOfANumber(
            sumOfDigits(parseInt(guessNumber))
          ).toString(),
          createdTime: selectedGameIndex === null ? new Date().toISOString() : createdTime,
        };
        const pastDaysGameList = getPastDaysGames(data?.guessingTableData ?? []);
        const currentDayGameList = getCurrentDayGames(
          data?.guessingTableData ?? []
        );
        if (selectedGameIndex === null) {
          createGuessTableAPICall(gameObject);
          currentDayGameList.push(gameObject);
        } else {
          updateGuessTableAPICall({...gameObject, guessGameId: guessGameId});
          //call here update api
          currentDayGameList[selectedGameIndex] = gameObject;
        }

        // let updatedData = {
        //   ...data,
        //   guessingTableData: [
        //     ...new Set([...pastDaysGameList, ...currentDayGameList]),
        //   ],
        // };
        // console.log("handleAddGameSubmit data", updatedData);
        // updateCollection(
        //   FIREBASE_COLLECTION_KEY,
        //   FIREBASE_COLLECTION_DOC_KEY,
        //   updatedData
        // )
        //   .then((response) => {
        //     console.info("firebase updated successfully!", response);
        //     setTimeout(() => {
        //       navigate(ROUTE_PATHS.HOME);
        //     }, 100);
        //   })
        //   .catch((e) => {
        //     console.error("firebase updated failed!", e);
        //   });
      } else {
        alert("Enter the 3 digit game numbers.");
      }
    },
    [data, navigate, guessNumber, selectedGameIndex]
  );

  return (
    <div className="login">
      <div className="container round_border_card">
        <h2 className="text-center">
          {selectedGameIndex === null
            ? "Add New Guessing Result"
            : "Update Guessing Result Detail"}
        </h2>
        <br />
        <form
          name="adminAddFatafatResultGame"
          validate
          onSubmit={handleAddGameSubmit}
          className="loginForm"
        >
          <div className="input-group mt-2">
            {/* <label className="label">Game Result</label> */}
            <div className="input-group mt-2">
            <label className="label">Game Name</label>
            <input
              // type="number"
              id="gameName"
              name="gameName"
              className="form-control"
              placeholder="Enter the game name"
              minLength={3}
              onChange={handleChange}
              value={gameName}
            />
            <span className="error-message"></span>
          </div>
          <div style={{ marginTop: 10}}>
            <input
              type="number"
              id="guessNumber"
              name="guessNumber"
              className="form-control"
              placeholder="Enter 3 digit game numbers"
              maxLength={3}
              minLength={3}
              onChange={handleChange}
              value={guessNumber}
            />
            <span className="error-message"></span>
            </div>
          </div>
          <br />
          <button className="button btn btn-custom btn-lg" type="submit">
            {selectedGameIndex === null ? "Add" : "Update"}
          </button>
          <button
            className="button btn btn-custom btn-lg mt-2 ml-0"
            onClick={() => navigate(ROUTE_PATHS.HOME)}
            style={{ marginTop: "10px" }}
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddEditGuessingTableScreen;
