export const navBarItems = [
    // {
    //     title: 'Home',
    //     hrefVal: './index.html'
    // },
    {
        title: 'SINGLE',
        hrefVal: 'single.html'
    },
    {
        title: 'JODI',
        hrefVal: 'jodi.html'
    },
    {
        title: 'SINGLE PATTI',
        hrefVal: 'single-patti.html'
    },
    {
        title: 'DOUBLE PATTI',
        hrefVal: 'double-patti.html'
    },
    {
        title: 'TRIPPLE PATTI',
        hrefVal: 'tripple-patti.html'
    },
    {
        title: 'JODI CHART',
        hrefVal: 'jodi-chart-list.html'
    },
    {
        title: 'PANEL CHART',
        hrefVal: 'panel-chart-list.html'
    },
    {
        title: 'HOW TO PLAY',
        hrefVal: 'how-to-play-matka.html'
    }
];

const singleHTML = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <title>Online Matka Play | Matka Result | Kalyan Matka | Satta Matka Online</title>
    <meta name="description" content="Trusted Website to Play Matka Online, This is the best satta matka play website, matka satta, sattamatka143, matka result, matka tips, mumbai tips matka, tips kalyan, bazar satta. Register For Free to Online Matka Play, Kalyan Matka, Mumbai Matka and More.">
    <meta name="keywords" content="online matka, matka online, online matka play, matka play, online satta matka, satta matka online, satta online play">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=1.0, minimum-scale=1.0, maximum-scale=1.0">
    <meta name="msvalidate.01" content="AFC5FAA75339993D84026C1109052FA7" />

    <meta name="rating" content="general">
    <meta name="robots" content="INDEX, FOLLOW, ARCHIVE" />
    <meta name="google" content="notranslate">
    <meta name="theme-color" content="#01439A">
    <meta property="og:locale" content="en_IN" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Online Matka Play | Matka Result | Kalyan Matka | Satta Matka Online - MatkaPlay.Net" />
    <meta property="og:description" content="Trusted Website to Play Matka Online, This is the best satta matka play website, matka satta, sattamatka143, matka result, matka tips, mumbai tips matka, tips kalyan, bazar satta. Register For Free to Online Matka Play, Kalyan Matka, Mumbai Matka and More."
    />
    <meta property="og:keywords" content="online matka, matka online, online matka play, matka play, online satta matka, satta matka online, satta online play">
    <meta property="og:url" content="https://matkaplay.net/" />
    <meta property="og:site_name" content="MatkaPlay" />
    <meta property="article:tag" content="Play Matka Online" />
    <meta property="og:image" content="Banner Image Path" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:description" content="Trusted Website to Play Matka Online, This is the best satta matka play website, matka satta, sattamatka143, matka result, matka tips, mumbai tips matka, tips kalyan, bazar satta. Register For Free to Online Matka Play, Kalyan Matka, Mumbai Matka and More."
    />
    <meta name="twitter:title" content="Online Matka Play | Matka Result | Kalyan Matka | Satta Matka Online - MatkaPlay.Net" />
    <meta name="mobile-web-app-capable" content="yes">
    <link rel="canonical" href="https://matkaplay.net/" />
    <link rel="icon" type="images/favicon.ico" href="https://matkaplay.net/assets/front/images/favicon.ico">

    <link rel="stylesheet" href="./assets/front/css/matkaplay.css">
    <script rel="dns-prefetch" src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.3/jquery.min.js"></script>
    <script type="application/ld+json">
        {
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            "name": "Matka Play",
            "image": "https://matkaplay.net/assets/front/images/banner.webp",
            "telephone": "08378855170",
            "url": "https://matkaplay.net/",
            "email": "matkaplaydotnet@gmail.com",
            "priceRange": "500-10000",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bhopal",
                "addressCountry": "India"
            },
            "review": {
                "@type": "Review",
                "author": {
                    "@type": "Person",
                    "name": "Matka Play"
                },
                "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": "10",
                    "bestRating": "10",
                    "worstRating": "06"
                },
                "reviewBody": "Welcome to Matka Play Website. Best Online Matka Play Website in the Industry with the most genuine online matka application"
            }
        }
    </script>

    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-131343480-1" rel="dns-prefetch"></script>
    <script>
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', 'UA-131343480-1');
    </script>
    <style>
        .padding-top {
            padding-top: 10%
        }

        .padding-bottom {
            padding-bottom: 10%
        }

        .white-text {
            color: #fff !important
        }

        .blue-background {
            background: #1a55a4
        }

        .text-center {
            text-align: center
        }

        .heading {
            text-align: center;
            font-size: 20px;
            background: rgba(1, 67, 154, .9);
            color: #fff;
            padding: 12px 0;
            border-radius: 4px;
            margin: 0 0 11px 0
        }

        .refresh-btn {
            position: fixed;
            bottom: 15px;
            left: 10px;
            background: black;
            color: white;
            padding: 8px;
            border-radius: 7px;
        }
    </style>
</head>

<body>

    <div class="container-fluid">
        <div class="row gold-top-hed">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-4">
                <div class="gold-logo">

                    <a href="./index.html"><img src="./assets/front/images/m-logo2.png"
                             alt="satta matka online logo" style="width:35px!important;height:35px!important"></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-8">
                <div class="gold-login-area">
                    <ul>
                        <li><a class="log-inn" href="./login.html"><i class="fa fa-lock" aria-hidden="true"></i>Login</a></li>
                        <li><a href="./registration.html"><i class="fa fa-user" aria-hidden="true"></i>Register</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row gold-menu-area">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top:20px">
                <div class="gold-menu">
                    <ul>
                        <li><a href="./index.html">HOME</a></li>
                        <li><a href="./single.html">SINGLE</a></li>
                        <li><a href="./jodi.html">JODI</a></li>
                        <li><a href="./single-patti.html">SINGLE PATTI</a></li>
                        <li><a href="./double-patti.html">DOUBLE PATTI</a></li>
                        <li><a href="./tripple-patti.html">TRIPPLE PATTI</a></li>                            
                        <li><a href="./jodi-chart-list.html">JODI CHART</a></li>
                        <li><a href="./panel-chart-list.html">PANEL CHART</a></li>
                        <li><a href="./how-to-play-matka.html">HOW TO PLAY</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>


    <noscript><meta http-equiv='refresh' content='0;url=/enable-js?js=0'> </noscript>
    <div class="main-body">
        <div class="container-fluid">
            <div class="container-fluid">
                <div class="row play-game-area">
                    <div class="container">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 play">
                            <div class="all-option">
                                <h2>SINGLE (10 KA 95)</h2>
                                <h3>Select Your Game</h3>
                                <form action="https://matkaplay.net/front/single_bet" method="POST" name="single_frm" id="single_frm">
                                    <div class="row main-top">
                                        <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
                                            <div class="sele-area">
                                                <select name="single_dropdown" id="single_dropdown">
<option value selected>------- Select Market -------</option>
<option value="open-153">MADHUR DAY OPEN (01:25 PM)</option>
<option value="close-153">MADHUR DAY CLOSE (02:25 PM)</option>
<option value="open-154">TARA MUMBAI DAY OPEN (01:30 PM)</option>
<option value="close-154">TARA MUMBAI DAY CLOSE (03:00 PM)</option>
<option value="open-155">MILAN DAY OPEN (02:55 PM)</option>
<option value="close-155">MILAN DAY CLOSE (04:55 PM)</option>
<option value="open-156">RAJDHANI DAY OPEN (03:00 PM)</option>
<option value="close-156">RAJDHANI DAY CLOSE (05:00 PM)</option>
<option value="open-157">KALYAN OPEN (04:10 PM)</option>
<option value="close-157">KALYAN CLOSE (06:10 PM)</option>
<option value="open-159">TARA MUMBAI NIGHT OPEN (08:30 PM)</option>
<option value="close-159">TARA MUMBAI NIGHT CLOSE (10:30 PM)</option>
<option value="open-161">MILAN NIGHT OPEN (08:45 PM)</option>
<option value="close-161">MILAN NIGHT CLOSE (10:55 PM)</option>
<option value="open-162">RAJDHANI NIGHT OPEN (09:20 PM)</option>
<option value="close-162">RAJDHANI NIGHT CLOSE (11:35 PM)</option>
<option value="open-167">KALYAN NIGHT OPEN (09:30 PM)</option>
<option value="close-167">KALYAN NIGHT CLOSE (11:30 PM)</option>
<option value="open-168">SUPER MATKA OPEN (05:00 PM)</option>
<option value="close-168">SUPER MATKA CLOSE (07:00 PM)</option>
<option value="open-169">MAIN MUMBAIDAY OPEN (03:20 PM)</option>
<option value="close-169">MAIN MUMBAIDAY CLOSE (05:20 PM)</option>
<option value="open-170">TIME NIGHT OPEN (08:00 PM)</option>
<option value="close-170">TIME NIGHT CLOSE (10:00 PM)</option>
<option value="open-171">TIME BAZAAR OPEN (12:55 PM)</option>
<option value="close-171">TIME BAZAAR CLOSE (01:55 PM)</option>
<option value="open-172">NAVRATNA DAY OPEN (01:10 PM)</option>
<option value="close-172">NAVRATNA DAY CLOSE (02:10 PM)</option>
<option value="open-173">NAVRATNA NIGHT OPEN (09:40 PM)</option>
<option value="close-173">NAVRATNA NIGHT CLOSE (11:40 PM)</option>
<option value="open-174">MADHUR NIGHT OPEN (08:20 PM)</option>
<option value="close-174">MADHUR NIGHT CLOSE (10:20 PM)</option>
<option value="open-175">MADHURI OPEN (11:45 AM)</option>
<option value="close-175">MADHURI CLOSE (12:45 PM)</option>
<option value="open-177">MADHURI NIGHT OPEN (06:45 PM)</option>
<option value="close-177">MADHURI NIGHT CLOSE (07:45 PM)</option>
<option value="open-178">MAIN BAZAR OPEN (09:30 PM)</option>
<option value="close-178">MAIN BAZAR CLOSE (11:45 PM)</option>
<option value="open-179">MILAN MORNING OPEN (10:10 AM)</option>
<option value="close-179">MILAN MORNING CLOSE (11:10 AM)</option>
<option value="open-180">SRIDEVI OPEN (11:35 AM)</option>
<option value="close-180">SRIDEVI CLOSE (12:35 PM)</option>
<option value="open-181">SRIDEVI NIGHT OPEN (07:05 PM)</option>
<option value="close-181">SRIDEVI NIGHT CLOSE (08:05 PM)</option>
<option value="open-183">SUPREME DAY OPEN (03:30 PM)</option>
<option value="close-183">SUPREME DAY CLOSE (05:30 PM)</option>
<option value="open-184">SUPREME NIGHT OPEN (08:35 PM)</option>
<option value="close-184">SUPREME NIGHT CLOSE (10:35 PM)</option>
<option value="open-185">TIME BAZAR MORNING OPEN (11:10 AM)</option>
<option value="close-185">TIME BAZAR MORNING CLOSE (12:10 PM)</option>
<option value="open-186">SRIDEVI MORNING OPEN (09:35 AM)</option>
<option value="close-186">SRIDEVI MORNING CLOSE (10:35 AM)</option>
</select>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12">
                                            <div class="sele-date-area">
                                                <select name="bet_date" id="bet_date">
<option value="2023-05-23">23 May 2023</option>
</select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sing-option">
                                        <div class="sing1 single6">
                                            <ul>
                                                <li><span>0</span><input type="number" class="nc-ch single_box" name="single[]" id="n0" min="10" /></li>
                                                <li><span>1</span><input type="number" class="nc-ch single_box" name="single[]" id="n1" min="10" /></li>
                                                <li><span>2</span><input type="number" class="nc-ch single_box" name="single[]" id="n2" min="10" /></li>
                                                <li><span>3</span><input type="number" class="nc-ch single_box" name="single[]" id="n3" min="10" /></li>
                                                <li><span>4</span><input type="number" class="nc-ch single_box" name="single[]" id="n4" min="10" /></li>
                                                <li><span>5</span><input type="number" class="nc-ch single_box" name="single[]" id="n5" min="10" /></li>
                                                <li><span>6</span><input type="number" class="nc-ch single_box" name="single[]" id="n6" min="10" /></li>
                                                <li><span>7</span><input type="number" class="nc-ch single_box" name="single[]" id="n7" min="10" /></li>
                                                <li><span>8</span><input type="number" class="nc-ch single_box" name="single[]" id="n8" min="10" /></li>
                                                <li><span>9</span><input type="number" class="nc-ch single_box" name="single[]" id="n9" min="10" /></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h4>total point : <span id="single_bet">00</span></h4>
                                    <div class="play-butt"><input type="submit" value="Submit Game"></div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="close-any">
                        <div class="popup">
                            <h2>Your Balance Is Low!!!</h2>
                            <p>Please Contact For Recharge Contact us on <a href="tel:6206574067">6206574067</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row flot-what">
        <a href="whatsapp://send?text=Hello Admin!&phone=+916206574067"><img src="https://matkaplay.net/assets/front/images/wa.png" alt="satta matka whatsapp" /></a>
        <a onclick="window.location.reload();" class="refresh-btn">REFRESH </a>
    </div>
    <div class="container-fluid">
        <div class="row gold-footer">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="g-foot">
                    <div class="foot-social-n">
                        <ul>
                            <li><a href="https://facebook.com/" target="_blank"><i class="fa fa-facebook-square" aria-hidden="true"></i></a></li>
                            <li><a href="https://twitter.com/" target="_blank"><i class="fa fa-twitter-square" aria-hidden="true"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                            <li><a href="https://www.youtube.com/" target="_blank"><i class="fa fa-youtube" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                    <div class="foot-one-link">
                        <ul>
                            <li><a href="https://shubxcobra.000webhostapp.com/">Online Matka Play</a></li>
                            <li><a href="https://shubxcobra.000webhostapp.com/">Satta Matka Online</a></li>
                            <li><a href="https://shubxcobra.000webhostapp.com/">Satta Matka</a></li>
                            <li><a href="https://shubxcobra.000webhostapp.com/">Kalyan Matka</a></li>
                            <li><a href="https://shubxcobra.000webhostapp.com/">SattaMatka143</a></li>
                            <li><a href="https://shubxcobra.000webhostapp.com/">Matka Result</a></li>
                    </div>
                    <div class="foot-cont">
                        <p style="padding-right: 10%; padding-left: 10%">Desclaimer: Purchase of online lottery using this website is prohibited in the territories where lotteries are banned. Playing online matka below 18 years is not acceptable. Call us on the given phone number to make a complaint.</p>
                    </div>
                    <p>Copy Right 2022</p>
                </div>
            </div>
        </div>
    </div>






    <script src="https://matkaplay.net/assets/front/js/matkaplay.js"></script>
    <script type="text/javascript">
        // Passive event listeners
        jQuery.event.special.touchstart = {
            setup: function(_, ns, handle) {
                this.addEventListener("touchstart", handle, {
                    passive: !ns.includes("noPreventDefault")
                });
            }
        };
        jQuery.event.special.touchmove = {
            setup: function(_, ns, handle) {
                this.addEventListener("touchmove", handle, {
                    passive: !ns.includes("noPreventDefault")
                });
            }
        };

        $(document).ready(function() {
            $('.closebutn').click(function() {
                $('.overlay').fadeOut();
                $('.successmessage').fadeOut();
                $('.errormessage').fadeOut();
            });
        });

        $(document).ready(function() {
            $('.overlay').delay(1000).fadeOut();
            $('.successmessage').delay(1000).fadeOut();
            $('.errormessage').delay(1000).fadeOut();
        });
    </script>
    <style>
        label.error {
            color: #dd0000;
            font-weight: normal;
            margin-bottom: 0px;
        }
    </style>
    <script type="text/javascript">
        $("document").ready(function() {

            $("#logform").validate({
                rules: {
                    email: {
                        "required": true,
                        "email": true,
                    },
                    password: "required"
                },
                messages: {
                    email: {
                        "required": "Please Enter Email..!!",
                        "email": "Please Enter Valid Email..!!",
                    },
                    password: "Please Enter Password..!!"
                }
            });

            $("#reg_frm").validate({
                rules: {
                    username: {
                        "required": true,
                        remote: {
                            url: "https://matkaplay.net/front/checkUnique",
                            type: "post",
                            data: {
                                field: "username",
                                value: function() {
                                    return $("#username").val();
                                }
                            }
                        }
                    },
                    email: {
                        required: true,
                        email: true,
                        remote: {
                            url: "https://matkaplay.net/front/checkUnique",
                            type: "post",
                            data: {
                                field: "email",
                                value: function() {
                                    return $("#email").val();
                                }
                            }
                        }
                    },
                    password: {
                        required: true,
                    },
                    mobile: {
                        required: true,
                        number: true,
                        remote: {
                            url: "https://matkaplay.net/front/checkUnique",
                            type: "post",
                            data: {
                                field: "mobile",
                                value: function() {
                                    return $("#mobile").val();
                                }
                            }
                        }
                    }
                },
                messages: {
                    username: {
                        "required": "Please Enter Username..!!",
                        "remote": "Username Already Exists..!!"
                    },
                    email: {
                        "required": "Please Enter Email..!!",
                        "email": "Please Enter a Valid Email..!!",
                        "remote": "Email Already Exists..!!"
                    },
                    password: {
                        "required": "Please Enter Password..!!"
                    },
                    mobile: {
                        "required": "Please Enter Mobile..!!",
                        "number": "Please Enter Number..!!",
                        "remote": "Mobile Already Exists..!!"
                    }
                },
                submitHandler: function(form) {
                    if ($('#agree_box').prop('checked')) {
                        form.submit();
                    } else {
                        $("#agree_box").parent().after('<label id="agree-box-error" class="error" for="agree_box">You must agree to the terms and conditions before registering..!!</label>');
                    }
                }
            });

            $("#log_frm").validate({
                rules: {
                    username: {
                        "required": true
                    },
                    password: {
                        required: true,
                    }
                },
                messages: {
                    username: {
                        "required": "Please Enter Username..!!"
                    },
                    password: {
                        "required": "Please Enter Password..!!"
                    }
                }
            });

            $("#frgt_frm").validate({
                rules: {
                    mobile: {
                        required: true,
                        number: true,
                        remote: {
                            url: "https://matkaplay.net/front/checkUnique1",
                            type: "post",
                            data: {
                                field: "mobile",
                                value: function() {
                                    return $("#mb").val();
                                }
                            }
                        }
                    }
                },
                messages: {
                    mobile: {
                        "required": "Please Enter Mobile..!!",
                        "number": "Please Enter a Valid Number..!!",
                        "remote": "Mobile  Number Doesn't Exists..!!"
                    }
                },
                submitHandler: function(form) {
                    jQuery.ajax({
                        url: 'https://matkaplay.net/front/forgot_password',
                        type: "post",
                        data: {
                            "mobile": $("#mb").val(),
                            "password": $("#pass1").val()
                        },
                        success: function() {
                            swal('OTP Sent');
                            $('.pop-forgot-area').hide();
                            $('.pop-otp-area').show();
                        },
                        error: function() {
                            $('.pop-forgot-area').hide();
                            swal('Oops...!!!Try Again.');
                        }
                    });
                }
            });

            $("#otp_frm").validate({
                rules: {
                    otp: "required",
                    password: "required",
                    password_again: {
                        equalTo: "#pass1"
                    }
                },
                messages: {
                    "otp": "Please Enter OTP..!!",
                    "password": "Please Enter Password..!!",
                    "password_again": "Password and Confirm Password Must be Same..!!"
                }
            });

            $("#single_frm").validate({
                rules: {
                    single_dropdown: {
                        "required": true
                    }
                },
                messages: {
                    single_dropdown: {
                        "required": "Please Select Market..!!"
                    }
                },
                errorPlacement: function(error, element) {
                    if (element.attr("name") == "single_dropdown") {
                        error.insertAfter(".main-top");
                    } else {
                        error.insertAfter(element);
                    }
                },
                submitHandler: function(form) {
                    var flag = 0;
                    $(".single_box").each(function(index) {
                        if ($(this).val() != "") {
                            flag = 1;
                        }
                    });
                    if (flag == 0) {
                        $(".sing-option").after('<label id="single_dropdown-error" class="error" for="single_dropdown">Please Bet on a Number..!!</label>');
                    } else {
                        var mystr = $("#single_dropdown :selected").text();
                        //Splitting it with : as the separator
                        var myarr = mystr.split("(");
                        var time = myarr[1].split(")");
                        $.ajax({
                            url: 'https://matkaplay.net/front/check_current_time',
                            method: "POST",
                            data: {
                                "time": time[0]
                            },
                            success: function(data) {
                                if (data == "true") {
                                    swal("Login to Bet on Single");
                                } else {
                                    swal("Bet time is over");
                                }
                            }
                        });
                    }
                }
            });

            $("#starline_single_frm").validate({
                rules: {
                    // single_dropdown:{
                    //   "required":true
                    // }
                },
                messages: {
                    // single_dropdown:{
                    //   "required":"Please Select Market..!!"
                    // }
                },
                errorPlacement: function(error, element) {
                    if (element.attr("name") == "single_dropdown") {
                        error.insertAfter(".main-top");
                    } else {
                        error.insertAfter(element);
                    }
                },
                submitHandler: function(form) {
                    var flag = 0;
                    $(".single_box").each(function(index) {
                        if ($(this).val() != "") {
                            flag = 1;
                        }
                    });
                    if (flag == 0) {
                        $(".sing-option").after('<label id="single_dropdown-error" class="error" for="single_dropdown">Please Bet on a Number..!!</label>');
                    } else {
                        var mystr = $("#srv_tm").text();
                        $.ajax({
                            url: 'https://matkaplay.net/front/check_current_time',
                            method: "POST",
                            data: {
                                "time": mystr
                            },
                            success: function(data) {
                                if (data == "true") {
                                    swal("Login to Bet on Single");
                                } else {
                                    swal("Bet time is over");
                                }
                            }
                        });
                    }
                }
            });

            $("#jodi_frm").validate({
                rules: {
                    jodi_dropdown: {
                        "required": true
                    }
                },
                messages: {
                    jodi_dropdown: {
                        "required": "Please Select Market..!!"
                    }
                },
                submitHandler: function(form) {
                    var flag = 0;
                    flg = 1;
                    $(".num").each(function(index) {
                        if ($(this).hasClass("select-cls")) {
                            flag = 1;
                        }
                    });
                    if (flag == 0) {
                        $(".number-area").after('<label id="jodi_dropdown-error" class="error" for="jodi_dropdown">Please Bet on a Number..!!</label>');
                    } else {
                        $(".clone-class").each(function(index) {
                            if ($(this).find("input").val() == "") {
                                flag = 0;
                            }
                        });
                        if (flag == 0) {
                            $(".show-input").after('<label id="jodi_select-error" class="error" for="jodi_select">Please Fill All the Amount Field..!!</label>');
                            $("#jodi_dropdown-error").hide();
                        } else {
                            var mystr = $("#jodi_dropdown :selected").text();
                            //Splitting it with : as the separator
                            var myarr = mystr.split("(");
                            var time = myarr[1].split(")");
                            $.ajax({
                                url: 'https://matkaplay.net/front/check_current_time',
                                method: "POST",
                                data: {
                                    "time": time[0]
                                },
                                success: function(data) {
                                    if (data == "true") {
                                        swal("Login to Bet on Jodi");
                                    } else {
                                        swal("Bet time is over");
                                    }
                                }
                            });
                        }
                    }
                }
            });

            $("#single_patti_frm").validate({
                rules: {
                    single_patti_dropdown: {
                        "required": true
                    }
                },
                messages: {
                    single_patti_dropdown: {
                        "required": "Please Select Market..!!"
                    }
                },
                submitHandler: function(form) {
                    var flag = 0;
                    // $( ".single-num" ).each(function( index ) {
                    //   if($(this).hasClass("select-cls"))
                    //   {
                    //     flag=1;
                    //   }
                    // });
                    // if(flag==0)
                    // {
                    //   $(".number-area").after('<label id="single_patti_dropdown-error" class="error" for="single_patti_dropdown">Please Bet on a Number..!!</label>');
                    // }
                    // else
                    // {
                    $(".single_patti_box").each(function(index) {
                        if ($(this).val() != "") {
                            flag = 1;
                        }
                    });
                    if (flag == 0) {
                        $(".sing-option").after('<label id="single_dropdown-error" class="error" for="single_dropdown">Please Bet on a Number..!!</label>');
                    } else {
                        // $( ".clone-class" ).each(function( index ) {
                        //   if($( this ).find("input").val()=="")
                        //   {
                        //     flag=0;
                        //   }
                        // });
                        // if(flag==0)
                        // {
                        //   $(".show-input").after('<label id="single_patti_select-error" class="error" for="single_patti_select">Please Fill All the Amount Field..!!</label>');
                        //   $("#single_patti_dropdown-error").hide();
                        // }
                        // else
                        // {
                        var mystr = $("#single_patti_dropdown :selected").text();
                        //Splitting it with : as the separator
                        var myarr = mystr.split("(");
                        var time = myarr[1].split(")");
                        $.ajax({
                            url: 'https://matkaplay.net/front/check_current_time',
                            method: "POST",
                            data: {
                                "time": time[0]
                            },
                            success: function(data) {
                                if (data == "true") {
                                    swal("Login to Bet on Single Patti");
                                } else {
                                    swal("Bet time is over");
                                }
                            }
                        });
                        // }
                    }
                }
            });

            $("#starline_single_patti_frm").validate({
                rules: {
                    // single_patti_dropdown:{
                    //   "required":true
                    // }
                },
                messages: {
                    // single_patti_dropdown:{
                    //   "required":"Please Select Market..!!"
                    // }
                },
                submitHandler: function(form) {
                    var flag = 0;
                    // $( ".single-num" ).each(function( index ) {
                    //   if($(this).hasClass("select-cls"))
                    //   {
                    //     flag=1;
                    //   }
                    // });
                    // if(flag==0)
                    // {
                    //   $(".number-area").after('<label id="single_patti_dropdown-error" class="error" for="single_patti_dropdown">Please Bet on a Number..!!</label>');
                    // }
                    // else
                    // {
                    $(".single_box").each(function(index) {
                        if ($(this).val() != "") {
                            flag = 1;
                        }
                    });
                    if (flag == 0) {
                        $(".sing-option").after('<label id="single_dropdown-error" class="error" for="single_dropdown">Please Bet on a Number..!!</label>');
                    } else {
                        // $( ".clone-class" ).each(function( index ) {
                        //   if($( this ).find("input").val()=="")
                        //   {
                        //     flag=0;
                        //   }
                        // });
                        // if(flag==0)
                        // {
                        //   $(".show-input").after('<label id="single_patti_select-error" class="error" for="single_patti_select">Please Fill All the Amount Field..!!</label>');
                        //   $("#single_patti_dropdown-error").hide();
                        // }
                        // else
                        // {
                        var mystr = $("#srv_tm").text();
                        $.ajax({
                            url: 'https://matkaplay.net/front/check_current_time',
                            method: "POST",
                            data: {
                                "time": mystr
                            },
                            success: function(data) {
                                if (data == "true") {
                                    swal("Login to Bet on Single Patti");
                                } else {
                                    swal("Bet time is over");
                                }
                            }
                        });
                        // }
                    }
                }
            });

            $("#double_patti_frm").validate({
                rules: {
                    double_patti_dropdown: {
                        "required": true
                    }
                },
                messages: {
                    double_patti_dropdown: {
                        "required": "Please Select Market..!!"
                    }
                },
                submitHandler: function(form) {
                    var flag = 0;
                    // $( ".double-num" ).each(function( index ) {
                    //   if($(this).hasClass("select-cls"))
                    //   {
                    //     flag=1;
                    //   }
                    // });
                    // if(flag==0)
                    // {
                    //   $(".number-area").after('<label id="double_patti_dropdown-error" class="error" for="double_patti_dropdown">Please Bet on a Number..!!</label>');
                    // }
                    // else
                    // {
                    $(".double_patti_box").each(function(index) {
                        if ($(this).val() != "") {
                            flag = 1;
                        }
                    });
                    if (flag == 0) {
                        $(".sing-option").after('<label id="single_dropdown-error" class="error" for="single_dropdown">Please Bet on a Number..!!</label>');
                    } else {
                        // $( ".clone-class" ).each(function( index ) {
                        //   if($( this ).find("input").val()=="")
                        //   {
                        //     flag=0;
                        //   }
                        // });
                        // if(flag==0)
                        // {
                        //   $(".show-input").after('<label id="double_patti_select-error" class="error" for="double_patti_select">Please Fill All the Amount Field..!!</label>');
                        //   $("#double_patti_dropdown-error").hide();
                        // }
                        // else
                        // {
                        var mystr = $("#double_patti_dropdown :selected").text();
                        //Splitting it with : as the separator
                        var myarr = mystr.split("(");
                        var time = myarr[1].split(")");
                        $.ajax({
                            url: 'https://matkaplay.net/front/check_current_time',
                            method: "POST",
                            data: {
                                "time": time[0]
                            },
                            success: function(data) {
                                if (data == "true") {
                                    swal("Login to Bet on Double Patti");
                                } else {
                                    swal("Bet time is over");
                                }
                            }
                        });
                        // }
                    }
                }
            });

            $("#starline_double_patti_frm").validate({
                rules: {
                    // single_patti_dropdown:{
                    //   "required":true
                    // }
                },
                messages: {
                    // single_patti_dropdown:{
                    //   "required":"Please Select Market..!!"
                    // }
                },
                submitHandler: function(form) {
                    var flag = 0;
                    // $( ".single-num" ).each(function( index ) {
                    //   if($(this).hasClass("select-cls"))
                    //   {
                    //     flag=1;
                    //   }
                    // });
                    // if(flag==0)
                    // {
                    //   $(".number-area").after('<label id="single_patti_dropdown-error" class="error" for="single_patti_dropdown">Please Bet on a Number..!!</label>');
                    // }
                    // else
                    // {
                    $(".single_box").each(function(index) {
                        if ($(this).val() != "") {
                            flag = 1;
                        }
                    });
                    if (flag == 0) {
                        $(".sing-option").after('<label id="single_dropdown-error" class="error" for="single_dropdown">Please Bet on a Number..!!</label>');
                    } else {
                        // $( ".clone-class" ).each(function( index ) {
                        //   if($( this ).find("input").val()=="")
                        //   {
                        //     flag=0;
                        //   }
                        // });
                        // if(flag==0)
                        // {
                        //   $(".show-input").after('<label id="single_patti_select-error" class="error" for="single_patti_select">Please Fill All the Amount Field..!!</label>');
                        //   $("#single_patti_dropdown-error").hide();
                        // }
                        // else
                        // {
                        var mystr = $("#srv_tm").text();
                        $.ajax({
                            url: 'https://matkaplay.net/front/check_current_time',
                            method: "POST",
                            data: {
                                "time": mystr
                            },
                            success: function(data) {
                                if (data == "true") {
                                    swal("Login to Bet on Double Patti");
                                } else {
                                    alert("Bet time is over");
                                }
                            }
                        });
                        // }
                    }
                }
            });
        });

        $(document).on("click", ".single-num", function() {
            if (!$(this).hasClass("select-cls")) {
                var i = 0;
                $(".single_patti_box").each(function(index) {
                    i++
                });
                var t_bet = 0;

                // if(parseInt(t_bet) + parseInt(i) >=100)
                // {
                //   swal("You Can Only Bet on 100 Single Patti Numbers Today");
                // }
                // else
                // {
                $(this).addClass("select-cls");
                var new_div = $("#clone-cls").clone().attr("id", "").addClass("clone-class").removeAttr("style").appendTo(".show-input");
                new_div.find(".b-num").html($(this).find("span").html());
                new_div.find("input").attr('name', 'quantity[' + $(this).find("span").html() + ']');
                // }
            } else {
                $(this).removeClass("select-cls");
                $("input[name='quantity[" + $(this).find("span").html() + "]']").parent().remove();
                // console.log($("input[name='quantity["+$(this).find("span").html()+"]']").parent());
            }
        });

        $(document).on("click", ".double-num", function() {
            if (!$(this).hasClass("select-cls")) {
                var i = 0;
                $(".double_patti_box").each(function(index) {
                    i++
                });
                var t_bet = 0;

                // if(parseInt(t_bet) + parseInt(i) >=100)
                // {
                //   swal("You Can Only Bet on 100 Double Patti Numbers Today");
                // }
                // else
                // {
                $(this).addClass("select-cls");
                var new_div = $("#clone-cls").clone().attr("id", "").addClass("clone-class").removeAttr("style").appendTo(".show-input");
                new_div.find(".b-num").html($(this).find("span").html());
                new_div.find("input").attr('name', 'quantity[' + $(this).find("span").html() + ']');
                // }
            } else {
                $(this).removeClass("select-cls");
                $("input[name='quantity[" + $(this).find("span").html() + "]']").parent().remove();
                // console.log($("input[name='quantity["+$(this).find("span").html()+"]']").parent());
            }
        });
    </script>
</body>

</html>`
export const HTML_CONTENT_DICTIONARY = {
    'single.html': singleHTML,
    // 'jodi.html': `${require('./html/jodi.html')}`,
    // 'single-patti.html': `${require('./html/single-patti.html')}`,
    // 'double-patti.html': `${require('./html/double-patti.html')}`,
    // 'tripple-patti.html': `${require('./html/tripple-patti.html')}`,
    // 'jodi-chart-list.html': `${require('./html/jodi-chart-list.html')}`,
    // 'panel-chart-list.html': `${require('./html/panel-chart-list.html')}`,
    // 'how-to-play-matka.html': `${require('./html/how-to-play-matka.html')}`,
}

export const matkaData = {
    infoCard: {
        title: 'Shanti Matka',
        hashTag: '#1 Most Trusted Matka Play Website',
        callUs: 'Call us:',
        contactNumber: '+919556538782',
        description: 'Shanti Bazar Matka is the best preferred platforms to play online satta matka. We provide you the best interface to guess and win huge amounts through us. We Gurantee you the best online matka play with kalyan tricks and tips to play matka online. Have the best experience through our matka website and play satta with your skill to get huge rewards. Call us on the mentioned number to know more.'
    },
    noticeBoard: {

    }
}

export const matkaData2 = {
    infoCard: {
        title: 'ONLINE MATKA GAME',
        hashTag: 'TATA BAZAR MATKA ONLINE WITH HIGHEST RATE SAME DAY WITHDRAWAL OF YOUR WINNING MATKA AMOUNT',
        deposite1: 'MINIMUM DEPOSIT:-500',
        withdrawal1: 'MINIMUM WITHDRAWAL:-1000',
        withdrawal2: 'MAXIMUM WITHDRAWAL:-NO LIMITS',
    },
    noticeBoard: {

    }
}

export const gameItems = {
    infoCard: {
        game1: 'Time Bazar',
        game2: 'Rajdhani',
        game3: 'Madhuri',
        game4: 'Tara Mumbai',
        game5: 'Madhur',
        game6: 'Navratna',
        game7: 'Main Mumbai',
        game8: 'Milan',
        game9: 'Kalyan',
        game10: 'Super Matka',
        game11: 'Madhuri',
        game12: 'SriDevi',
        game13: 'Bhootnath',
        game14: 'Supreme',
        game15: 'Kalyan',
        game16: 'Main Bazar' 
    },
    noticeBoard: {

    }
}


export const gameTimeItems = [
    {
        title: 'Time Bazar',

    },
    {
        title: 'Rajdhani',

    },
    {
        title: 'Madhuri',

    },
    {
        title: 'Tara Mumbai Day',

    },
    {
        title: 'Madhur Day',

    },
    {
        title: 'Navratna Day',

    },
    {
        title: 'Main Mumbai Day',

    },
    {
        title: 'Rajdhani Day',

    },
    {
        title: 'Milan Day',

    },
    {
        title: 'Kalyan',

    },
    {
        title: 'Super Matka',

    },
    {
        title: 'Madhuri Day',

    },
    {
        title: 'SriDevi Night',

    },
    {
        title: 'Time Night',

    },
    {
        title: 'Tara Mumbai Night',

    },
    {
        title: 'Madhur Night',

    },
    {
        title: 'Supreme Night',

    },
    {
        title: 'Milan Night',

    },
    {
        title: 'Rajdhani Night',

    },
    {
        title: 'Kalyan Night',

    },
    {
        title: 'Main Bazar',

    },
    {
        title: 'Navratna Night',

    },

];