import React from "react";

import "../css/style.min.css";
import "../css/matkaplay.css";
import "../../common.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialLinksFooterCard = ({ data }) => {
  return (
    <div className="container-fluid">
      <div className="row gold-footer">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
          <div className="g-foot" >
          <div className="foot-social-n" >
              <ul>
                {data.socialLinks.map((item, index) => {
                  if (item.isDisabled) {
                    return null;
                  }
                  return (
                    <li className="mt-2" key={`socialicons-${index}`}>
                      <a
                        href={item.url && item.url !== "" ? item.url : "/"}
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ color: "#fff" }}
                      >
                        <FontAwesomeIcon
                          icon={item.iconValue}
                          size={item.size}
                        />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* <div className="foot-social-n">
              <ul>
                <li>
                  <a
                    href="https://facebook.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook-square" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-twitter-square" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-youtube" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div> */}
            <div className="foot-one-link">
              <ul>
                <li>
                  <a href="https://shubxcobra.000webhostapp.com/">
                    Online Matka Play
                  </a>
                </li>
                <li>
                  <a href="https://shubxcobra.000webhostapp.com/">
                    Satta Matka Online
                  </a>
                </li>
                <li>
                  <a href="https://shubxcobra.000webhostapp.com/">
                    Satta Matka
                  </a>
                </li>
                <li>
                  <a href="https://shubxcobra.000webhostapp.com/">
                    Kalyan Matka
                  </a>
                </li>
                <li>
                  <a href="https://shubxcobra.000webhostapp.com/">
                    SattaMatka143
                  </a>
                </li>
                <li>
                  <a href="https://shubxcobra.000webhostapp.com/">
                    Matka Result
                  </a>
                </li>
              </ul>
            </div>
            <div className="foot-cont">
              <p style={{ paddingHorizontal: "10%" }}>
                Desclaimer: Purchase of online lottery using this website is
                prohibited in the territories where lotteries are banned.
                Playing online matka below 18 years is not acceptable. Call us
                on the given phone number to make a complaint.
              </p>
            </div>
            <p>Copy Right 2022</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialLinksFooterCard;
