import React, { useCallback, useState } from "react";
import { updateCollection } from "../../services/firebase";
import {
  FIREBASE_COLLECTION_DOC_KEY,
  FIREBASE_COLLECTION_KEY,
  ROUTE_PATHS,
} from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAddUpdateGameInitialState,
  getCentralMatkaNumber,
  getCurrentDayGames,
  getPastDaysGames,
  getSelectedParam,
  isEmpty,
  onTimeChange,
  sortListByHHMMTime,
} from "../../util";

import "./css/style.min.css";
import "./css/matkaplay.css";
import "../common.css";
import { APIService } from "../../App";
import { API_CONFIGS } from "../../services/fetchAPI/constants";

const AddGameScreen = ({ 
  data, 
  gamesList,
  gamesTimeTableList,
  guessingTableList,
  fatafatTableList, 
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const selectedGameIndex = getSelectedParam(params);

  const [
    { gameName, startTime, endTime, lhsValue, rhsValue, createdTime },
    setState,
  ] = useState(
    getAddUpdateGameInitialState(
      getCurrentDayGames(gamesList),
      selectedGameIndex
    )
  );
  const [selectedGameTimeIndex, setSelectedGameTimeIndex] = useState(
    parseInt(selectedGameIndex ?? 0) ?? 0
  );

  const sortedGameTimeTableList = gamesList; //sortListByHHMMTime(data.gamesTimeTable);
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;

    setState((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const createGameAPICall = (body) => {
    APIService.post(API_CONFIGS.CREATE_GAME.URL, body)
      .then((response) => {
        // setData([...data, response.data]);
        console.log("createGameAPICall API_CONFIGS.CREATE_GAME.URL", {body, response});
        if (response?.data?.status && response?.data?.model) {
          alert(`Success:${response?.data?.sucessMsg}`);
          navigate(ROUTE_PATHS.HOME);
        } else if (response?.data?.errMessages && response?.data?.errMessages.length > 0) {
          // throw new Error(` error.: ${response}`); 
          const errorMSg = response.data.errMessages[0].errMessage;
          alert(`Error:${errorMSg}`);
        }
      })
      .catch((error) => {
        console.error("Error createGameAPICall:", error);
        alert(error);
      });
  }
  const updateGameAPICall = (body) => {
    console.log("updateGameAPICall body", body);
    APIService.post(API_CONFIGS.UPDATE_GAME.URL, body)
      .then((response) => {
        // setData([...data, response.data]);
        console.log("updateGameAPICall API_CONFIGS.UPDATE_GAME.URL", response);
        if (response?.data?.status && response.data.model) {
          navigate(ROUTE_PATHS.HOME);
        } else if (response?.data?.errMessages && response?.data?.errMessages.length > 0) {
          // throw new Error(` error.: ${response}`); 
          const errorMSg = response.data.errMessages[0].errMessage;
          alert(errorMSg);
        }
      })
      .catch((error) => {
        console.error("Error updateGameAPICall:", error);
        alert(error);
      });
  }

  const handleAddGameSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      // console.log(selectedGameTimeIndex);

      if (selectedGameTimeIndex !== null) {
        
        // const selectedGame = selectedGameIndex === null ? sortedGameTimeTableList[selectedGameTimeIndex] : data.gameTypes.filter(item => item.guessGameTypeId === selectedGameIndex);
        const selectedGame = sortedGameTimeTableList[selectedGameTimeIndex];
        console.log('oncreate handle:', { selectedGame, selectedGameTimeIndex })
        const gameObject = {
          // name: selectedGame.gamesTimeTableUI.name,
          // startTime: selectedGame.gamesTimeTableUI.startTime,
          // endTime: selectedGame.gamesTimeTableUI.endTime,
          gamesTimeTableId: selectedGame.gamesTimeTableUI.gamesTimeTableId,
          lhsValue: !isEmpty(lhsValue) ? lhsValue : "***",
          rhsValue: !isEmpty(rhsValue) ? rhsValue : "***",
          winnerValue: getCentralMatkaNumber(
            parseInt(lhsValue),
            parseInt(rhsValue)
          ),
          createdTime:
            selectedGameIndex === null || createdTime === ""
              ? new Date().toISOString()
              : createdTime,
        };

        const pastDaysGameList = getPastDaysGames(data.gameTypes);
        const currentDayGameList = getCurrentDayGames(data.gameTypes);
        console.log('edit selectedGameIndex :', {selectedGameIndex, gameObject});
        if (selectedGameIndex === null) {
          // currentDayGameList.push(gameObject);
          createGameAPICall(gameObject);
        } else {
          // const foundObj = data.gameTypes.filter(item => item.guessGameTypeId === selectedGameIndex);
          // const foundObj = sortListByHHMMTime(gamesList)[selectedGameIndex];
          const foundObj = gamesList[selectedGameIndex];
          const gameObjectUpdated = {
            // ...foundObj,
            guessGameTypeId: foundObj.guessGameTypeId,
            createdTime: foundObj.createdTime,
            gamesTimeTableId: foundObj.gamesTimeTableUI.gamesTimeTableId,
            lhsValue: !isEmpty(lhsValue) ? lhsValue : "***",
            rhsValue: !isEmpty(rhsValue) ? rhsValue : "***",
            winnerValue: getCentralMatkaNumber(
              parseInt(lhsValue),
              parseInt(rhsValue)
            ),
          };
          console.log('oncreate foundObj:', { gameObjectUpdated, foundObj })
          // currentDayGameList[selectedGameIndex] = gameObject;
          updateGameAPICall(gameObjectUpdated);
        }
        // const updatedGames = [...new Set([...pastDaysGameList, ...currentDayGameList])];
        //  console.log('currentDayGameList:', {currentDayGameList, pastDaysGameList, updatedGames});

        // let updatedData = {
        //   ...data,
        //   gameTypes: updatedGames,
        // };
        // console.log("handleAddGameSubmit data", { gameObject, updatedData });
        // updateCollection(
        //   FIREBASE_COLLECTION_KEY,
        //   FIREBASE_COLLECTION_DOC_KEY,
        //   updatedData
        // )
        //   .then((response) => {
        //     console.info("firebase updated successfully!", response);
        //     navigate(ROUTE_PATHS.HOME);
        //   })
        //   .catch((e) => {
        //     console.error("firebase updated failed!", e);
        //   });
      } else {
        alert("Enter all the details.");
      }
      //
    },
    [
      data,
      gamesList,
      // endTime,
      // gameName,
      lhsValue,
      // navigate,
      rhsValue,
      // selectedGameIndex,
      // startTime,
      selectedGameTimeIndex,
    ]
  );
  return (
    <div className="login">
      <div className="container round_border_card">
        <h2 className="text-center">
          {selectedGameIndex === null ? "Add New Game" : "Update Game Detail"}
        </h2>
        <br />
        <form
          name="adminAddGame"
          validate
          onSubmit={handleAddGameSubmit}
          className="loginForm"
        >
          <div className="input-group">
            {sortedGameTimeTableList.map((gameType, index) => {
              const listItem = `${onTimeChange(
                        gameType.gamesTimeTableUI.startTime
                      )} - ${onTimeChange(gameType.gamesTimeTableUI.endTime)} ${
                        gameType.gamesTimeTableUI.name
                      }`;
              return (
                <tbody key={`${gameType.gamesTimeTableUI.name}_${index}`}>
                  <tr>
                    <td>
                      <input
                        type="radio"
                        id="selectedGameTime"
                        name="selectedGameTime"
                        // className="mb-0"
                        required
                        disabled={
                          selectedGameIndex !== null
                            ? selectedGameTimeIndex !== index
                            : false
                        }
                        value={listItem}
                        checked={selectedGameTimeIndex === index}
                        onChange={(e) => {
                          console.log('onchange bullet', {e: e.target.value, index})
                          setSelectedGameTimeIndex(index)
                        }}
                      />
                      <span className="font-weight-bold ml-3">{listItem}</span>
                    </td>
                  </tr>
                </tbody>
              );
            })}
            {/* <label className="label">Game Name</label>
              <input
                type="text"
                id="gameName"
                name="gameName"
                className="form-control"
                placeholder="Enter the Game Name*"
                required
                onChange={handleChange}
                value={gameName}
              /> */}
            <span className="error-message"></span>
          </div>
          {/* <div className="input-group mt-2">
              <label className="label">Start Time</label>
              <input
                type="time"
                id="startTime"
                name="startTime"
                className="form-control"
                placeholder="Enter the start time*"
                required
                onChange={handleChange}
                value={startTime}
              />
              <span className="error-message"></span>
            </div> */}
          {/* <div className="input-group mt-2">
              <label className="label">End Time</label>
              <input
                type="time"
                id="endTime"
                name="endTime"
                className="form-control"
                placeholder="Enter the end time*"
                required
                onChange={handleChange}
                value={endTime}
              />
              <span className="error-message"></span>
            </div> */}
          <div className="input-group mt-2">
            <label className="label">First Game Digits</label>
            <input
              type="number"
              id="lhsValue"
              name="lhsValue"
              className="form-control"
              placeholder="Enter the first game digits*"
              maxLength={3}
              minLength={3}
              onChange={handleChange}
              value={lhsValue}
            />
            <span className="error-message"></span>
          </div>
          <div className="input-group mt-2">
            <label className="label">Second Game Digits</label>
            <input
              type="number"
              id="rhsValue"
              name="rhsValue"
              className="form-control"
              placeholder="Enter the second game digits*"
              maxLength={3}
              minLength={3}
              onChange={handleChange}
              value={rhsValue}
            />
            <span className="error-message"></span>
          </div>
          <br />
          <button className="button btn btn-custom btn-lg" type="submit">
            {selectedGameIndex === null ? "Add" : "Update"}
          </button>
          <button
            className="button btn btn-custom btn-lg mt-2 ml-0"
            onClick={() => navigate(ROUTE_PATHS.HOME)}
            style={{ marginTop: "10px" }}
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddGameScreen;
