import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../constants";
import { getCurrentDayGames } from "../../../util";
import editIcon from "../images/edit.png";
import deleteIcon from "../images/delete_red.png";
import { APIService } from "../../../App";
import { API_CONFIGS } from "../../../services/fetchAPI/constants";

const GuessingTableCard = ({ isAdminLoggedIn, data = [] }) => {
  const navigate = useNavigate();
  // const todaysGames = getCurrentDayGames(data);

  const onEditGame = useCallback(
    (guessGameId) => {
      navigate(`${ROUTE_PATHS.ADD_EDIT_GUESSING_TABLE_RESULT}/${guessGameId}`);
    },
    [navigate]
  );

  const onDeleteGuessTableAPICall = (body) => {
    APIService.post(API_CONFIGS.REMOVE_GUESS_TABLE.URL, body)
      .then((response) => {
        // setData([...data, response.data]);
        console.log(
          "deleteGuessTableAPICall API_CONFIGS.REMOVE_GUESS_TABLE.URL",
          response
        );
        if (response?.data?.status) {
          alert(`Success: ${response?.data?.sucessMsg}`);
          window.location.reload();
        } else if (
          response?.data?.errMessages &&
          response?.data?.errMessages.length > 0
        ) {
          const errorMSg = response.data.errMessages[0].errMessage;
          alert(errorMSg);
        }
      })
      .catch((error) => {
        console.error("Error deleteGuessTableAPICall:", error);
      });
  };

  const onDelete = (body) => {
    if (window.confirm("Are you sure you want to delete this game?")) {
      // Save it!
      onDeleteGuessTableAPICall(body);
    } else {
      // Do nothing!
      console.log("Thing was not deleted to the database.");
    }
  };
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="matka-results" style={{ paddingBottom: 0 }}>
          <h2>Guessing Table</h2>
          <div className="test">
            {isAdminLoggedIn && (
              <div className="d-flex align-items-center test">
                {/* <img
                  className="img-btn"
                  src={editIcon}
                  title="Edit game"
                  alt="edit-game"
                  onClick={onEditGame}
                /> */}
                <Link
                  to={ROUTE_PATHS.ADD_EDIT_GUESSING_TABLE_RESULT}
                  title="Add Fatafat Result"
                  className="add-fatafat-btn"
                >
                  <b>+</b>
                </Link>
              </div>
            )}
            {/* <Link
              to={ROUTE_PATHS.FATAFAT_RESULT_DETAIL}
              title="Fatafat chart"
              className="shantiMatkaChartBtn"
            >
              Chart
            </Link> */}
          </div>
        </div>
        {data.length === 0 ? (
          <div className="shadow_card">
            <h1>No results available!</h1>
          </div>
        ) : (
          <>
            {data.map((item, index) => {
              return (
                <div
                  // className="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                  key={`guessgame-${item?.guessGameId}-${index}`}
                >
                  {/* <div style={{ justifyContent: 'center', alignItems: 'center', backgroun: '#FFFFFF', height: 40, width: 80, margin: 5, padding: 5, boxShadow:  }}> */}
                  <div className="single-result-guess-card">
                    <div key={`guesstable-${index}`} style={{ display: 'flex'}}>
                      <div style={{ width: '75%'}}>
                      {item?.gameName && (
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            paddingRight: 5,
                          }}
                        >
                          {item.gameName}:
                        </span>
                      )}
                      <span
                        style={{
                          fontSize: 18,
                          fontWeight: 400,
                          marginHorizontal: 5,
                        }}
                      >
                        {item.guessNumber} - {item.winnerValue}
                      </span>
                      </div>
                      {isAdminLoggedIn && (
                        <div style={{ width: '25%' }}>
                          <img
                            className="img-btn"
                            style={{ height: 28, width: 28 }}
                            src={editIcon}
                            title="Edit game"
                            alt="edit-game"
                            onClick={() => onEditGame(item.guessGameId)}
                          />
                          <img
                            className="img-btn"
                            style={{ height: 28, width: 28 }}
                            src={deleteIcon}
                            title={`delete game`}
                            alt={`delete-game`}
                            onClick={() =>
                              onDelete({ guessGameId: item.guessGameId })
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default GuessingTableCard;
